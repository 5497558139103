import axios from "axios"

const token = localStorage.getItem('token');
const phone = localStorage.getItem('phone');

const config = {
	headers: {
	  'Authorization': `Basic ${token}` 
	}
}

export class ReferCodeService {
	static addUrlCode() {
		return axios.post(process.env.REACT_APP_MAIN_API_URL + "/addurlcode", {
			"phone": phone
		}, config)
	}
	static getUrlCode() {
		if(phone) {
			return axios.get(process.env.REACT_APP_MAIN_API_URL + `/getcode/${phone}`, config)
		}
		
	}
}