import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import PhoneInput from 'react-phone-input-2'

import 'react-phone-input-2/lib/style.css'
import cl from '../style/Login.module.css';
import logo from '../assets/logo.svg'
import ReactCodeInput from 'react-code-input';
import { LoginService } from '../service/LoginService';
import LoadingOverlay from '../components/LoadingOverlay';


function Login() {

	const navigate = useNavigate();

	const [countryPhone, setCountryPhone] = useState(null)
	const [code, setCode] = useState('')
	const [error, setError] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [loadingOverlay, setLoadingOverlay] = useState(false)
	const [resend, setResend] = useState(false)
	const [options, setOptions] = useState({
		token: '',
		sixDigitCode: ''
	})
	const [press, setPress] = useState(false)


	const checkToken = async () => {
		
		//*prod 
		setLoadingOverlay(true)
		const token = localStorage.getItem('token');
		if(token) {
			try{
				const data = await LoginService.checkToken()
				if(data) {
					navigate('dashboard')
				}
			} catch(e) {
				
				if(e.response.status === 403) {
					setError('The token has expired or the token is invalid. Log in again.')
				}
				if(e.response.status !== 403) {
					setError('Something went wrong. Try to login again')
				}
			}
		}
		setLoadingOverlay(false)
		
		/*
		//*test
		const token = await localStorage.getItem('token');
		if (token) {
			navigate('dashboard')
		}
		*/
	}

	useEffect(() => {
		checkToken()
	}, [])

	const handleLogin = async (pNumber) => {
		const data = await LoginService.login(`+${pNumber}`)
		if (data) {
			setPress(true)
			setTimeout(() => {
				setResend(true)
			}, 15000);
		}
		setIsLoading(false)
	}

	const sendPhone = async () => {
		/*
		//*dev
		setCountryPhone('+3809898416+62')
		setError('')
		setPress(true)
		setTimeout(() => {
			setResend(true)
		}, 2000);

		*/
		
		//*prod
		if (!countryPhone || countryPhone.length < 12) {
			setError('The number is not valid')
			return
		} else {
			setError('')
			setIsLoading(true)
		
			try {
				handleLogin(countryPhone)
			} catch (e) {
				setError(e.message)
				setPress(false)
				setIsLoading(false)
			} 
		}
		
	}
	const sendCode = async () => {
		//console.log(code);
		//navigate('dashboard')

		try {
			setIsLoading(true)
			const data = await LoginService.checkSentCode(code, `+${countryPhone}`)
			if (data) {
				//console.log(data);
				setError('')
				localStorage.setItem('token', data.data.accessToken);
				localStorage.setItem('phone', `+${countryPhone}`);
				navigate('dashboard')
			} else {
				setError('Something wrong')
			}
		} catch (e) {
			if (e.response.status === 403) {
				setError('The entered code is invalid')
			} else {
				setError(e.message)
			}
		} finally {
			setIsLoading(false)
		}
	}

	const resendCode = () => {
		setResend(false)

		setIsLoading(true)
		try {
			handleLogin(countryPhone)
		} catch (e) {
			setError(e.message)
			setPress(false)
			setIsLoading(false)
		}
		

	}
	const changePhone = () => {
		setPress(false)
		setResend(false)
	}

	if (loadingOverlay) {
		return <LoadingOverlay />
	}

	return (
		<div className='screen'>
			<div className={cl.container}>
				<div className={cl.images}>
					<img className='myIcon' src={logo} alt='Transport for London' />
				</div>
				<div className={cl.form}>
					{press
						? <div className={cl.form__line} style={{ marginBottom: 0 }} >
							<h3>Verify your phone number</h3>
							<article>SMS with a verification code has been sent to +{countryPhone[0]}{countryPhone[1]}{countryPhone[2]}********{countryPhone[countryPhone.length - 2]}{countryPhone[countryPhone.length - 1]}</article>
						</div>
						: <div className={`${cl.form__line} ${error && cl.error}`}>
							<h3>Verification</h3>
							<article>Enter your phone number to verify your account</article>
							<PhoneInput
								country={'gb'}
								preferredCountries={['gb', 'ua']}
								regions={'europe'}
								searchPlaceholder={'search'}
								autocompleteSearch={true}
								inputProps={{
									name: 'phone',
									required: true,
									autoFocus: true
								}}
								placeholder={'+44 9999 99999'}
								isValid={Boolean(!error)}
								value={countryPhone}
								onChange={phone => setCountryPhone(phone)}
							/>
						</div>
					}

					{press && <ReactCodeInput type='tel' fields={6} name={''} inputMode={'numeric'} onChange={e => setCode(e)} />}


					{error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}

					{isLoading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ProgressSpinner style={{ width: '30px', height: '30px' }} /></div>}

					{resend && <div className={cl.resend}>
						<p onClick={resendCode}>Haven't received code? <span>Resend</span></p>
					</div>}

					{press && <button className={cl.button} onClick={sendCode} style={{ width: '145px', textAlign: 'center' }}>Verify</button>}
					{!press && <button className={cl.button} style={{ alignSelf: 'flex-end' }} onClick={sendPhone}>Send code</button>}
				</div>

				{press && <div style={{ position: 'fixed', top: '20px', left: '20px', display: 'flex', alignItems: 'center' }}>
					<Button icon="pi pi-arrow-left" onClick={changePhone} className="p-button-rounded p-button-text" aria-label="Back" /> Back
				</div>
				}



			</div>
		</div>
	)

}

export default Login