import React, { useState, useEffect, useRef } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { useFetching } from '../hooks/useFetching';
import { TableUsersService } from '../service/TableUsersService';
import TableSkeleton from '../components/TableSkeleton';
import MyModal from '../components/MyModal';

function Table() {

	let emptyUser = {
		userKey: null,
		userNumber: null,
		lastActivity: null,
		watchedVideo: '',
		videoStatus: []
	};
	//offline test
	const dumData = [{ userKey: 1, userNumber: '+386561512151111' },
	{ userKey: 2, userNumber: '+386561512151991' },
	{ userKey: 3, userNumber: '+386561512151331' },]

	const [mobile, setMobile] = useState(false)
	const [heightTable, setHeightTable] = useState(null)
	const [users, setUsers] = useState([])
	const [filteredUsers, setFilteredUsers] = useState([])
	const [user, setUser] = useState(emptyUser)
	const [deleteUsertDialog, setDeleteUserDialog] = useState(false);
	const toast = useRef(null);

	const [fetchUsers, usersIsLoading, usersError] = useFetching(async () => {
		const data = await TableUsersService.getUsers()
		if (data) {
			const users = data.data.userlist.filter(val => val.ADMIN === 0)
			setUsers(users)
			setFilteredUsers(users)
		}
	})
	const [deleteUser, deleteUserIsLoading, deleteUserError] = useFetching(async () => {
		await TableUsersService.deleteUser(user.userNumber)
		if (!deleteUserError) {
			let _users = users.filter(val => val.userKey !== user.userKey);
			setUsers(_users);
			setFilteredUsers(_users);
			setUser(emptyUser);
			toast.current.show({ severity: 'success', summary: 'Successful', detail: 'User Deleted', life: 3000 });
		}
	})

	useEffect(() => {
		// @ts-ignore
		if (window.innerWidth < 500) {
			setMobile(true)
			setHeightTable(window.innerHeight - 120)
		}
		fetchUsers()
	}, [])

	useEffect(() => {
		if (usersError || deleteUserError) {
			toast.current.show({ sticky: true, severity: 'error', summary: 'Error', detail: usersError || deleteUserError });
		}
	}, [usersError, deleteUserError])

	const confirmDeleteUser = (user) => {
		setUser(user);
		setDeleteUserDialog(true);
	}

	const handleDeleteUser = () => {
		setDeleteUserDialog(false);
		deleteUser()
	}

	const actionBodyTemplate = (rowData) => {
		const loading = rowData.userKey === user.userKey && deleteUserIsLoading
		return (
			<React.Fragment>
				<Button tooltip='Delete' tooltipOptions={{ position: 'top' }} icon="pi pi-trash" loading={loading} className="p-button-rounded p-button-text" style={{ color: 'var(--red-500)' }} onClick={() => confirmDeleteUser(rowData)} />
			</React.Fragment>
		);
	}

	const hideDeleteUserDialog = () => {
		setDeleteUserDialog(false);
	}

	const [globalFilter, setGlobalFilter] = useState(' ');

	const handleChangeInput = (e) => {
		setFilteredUsers(users.filter(item => item.userNumber.includes(e.target.value)))
		//users.filter(item => item.userNumber.includes(e.target.value))
	}


	const renderHeader = () => {
		return (
			<div>
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText type="search" onChange={(e) => handleChangeInput(e)} placeholder="Search..." />
				</span>
			</div>
		)
	}
	const header = renderHeader();

	return (
		<div>
			<Toast ref={toast} />
			{users.length > 0 &&
				<DataTable value={filteredUsers} scrollable={mobile} scrollHeight={`${heightTable}px`} header={header} sortMode="multiple" responsiveLayout="scroll" >
					<Column body={(data, options) => options.rowIndex + 1} style={{maxWidth: '58px'}} header="#"></Column>
					<Column field="userNumber" header="Phone Number" sortable></Column>
					<Column field="watchedVideo" header="Videos watched"  sortable></Column>
					<Column field="lastActivity" header="Last activity" sortable></Column>
					<Column className='actionsColumn' body={actionBodyTemplate} exportable={false} style={{ minWidth: '1rem' }}></Column>
				</DataTable>
			}
			{users.length === 0 && usersError && <p>Users not found</p>}
			{usersIsLoading && <TableSkeleton />}
			<MyModal actionYes={handleDeleteUser} actionNo={hideDeleteUserDialog} visible={deleteUsertDialog} onHide={hideDeleteUserDialog}>
				{user && <span>Are you sure you want to delete <b style={{ color: 'var(--red-500)' }}>{user.userNumber}</b>?</span>}
			</MyModal>

		</div>
	)
}

export default Table