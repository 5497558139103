import React from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';


function MyModal({actionYes, actionNo, visible, onHide, children}) {

	const dialogFooter = (
		<React.Fragment>
			<Button label="Yes" className="p-button-text" onClick={actionYes} />
			<Button label="No" className="p-button-text" onClick={actionNo} />
		</React.Fragment>
	);

	return (
		<Dialog visible={visible} modal footer={dialogFooter} onHide={onHide}>
			<div className="confirmation-content">
				{children}
			</div>
		</Dialog>
	)
}

export default MyModal