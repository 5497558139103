import React, { useState } from 'react'
import { TabView, TabPanel } from 'primereact/tabview';
import '../style/Tabs.css'
import ReferCode from './ReferCode';
import Table from './Table';

function Tabs() {
	const [activeIndex, setActiveIndex] = useState(0)

	return (
		<TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
			{/*<TabPanel header="Refer Link">
				<ReferCode />
			</TabPanel>*/}
			<TabPanel header="Users">
				<Table />
			</TabPanel>
		</TabView>
	)
}

export default Tabs