import React from 'react'
import { ProgressSpinner } from 'primereact/progressspinner';

import cl from './LoadingOverlay.module.css'
function LoadingOverlay() {
  return (
	 <div className={cl.screen}><ProgressSpinner /></div>
  )
}

export default LoadingOverlay