import React, { useState } from 'react'
import { Menubar } from 'primereact/menubar';
import { Button } from 'primereact/button';
import { Outlet, useNavigate } from 'react-router-dom';
import MyModal from '../components/MyModal';

function Header() {
	const navigate = useNavigate();
	const [logoutDialog, setLogoutDialog] = useState(false)

	const handleLogout = () => {
		localStorage.removeItem('token')
		localStorage.removeItem('phone')
		navigate('/')
	}

	const hideLogoutDialog = () => {
		setLogoutDialog(false)
	}

	return (
		<>
			<Menubar
				style={{ backgroundColor: 'var(--primary-500)', color: '#fff' }}
				model={null}
				start={<h1>Dashboard</h1>}
				end={<Button label="Logout" icon="pi pi-power-off" onClick={() => setLogoutDialog(true)} />}
			/>
			<Outlet />
			<MyModal actionYes={handleLogout} actionNo={hideLogoutDialog} visible={logoutDialog} onHide={hideLogoutDialog}>
				<span>Are you sure you want to logout?</span>
			</MyModal>
		</>
	)
}

export default Header