import React from 'react'
import { Skeleton } from 'primereact/skeleton';

import cl from './TableSkeleton.module.css'

function LineSkeletonTable() {
	return (
		<div className={cl.line}>
			<Skeleton width="15%" height="2rem" animation="wave" />
			<Skeleton width="63%" height="2rem" animation="wave" />
			<Skeleton width="20%" height="2rem" animation="wave" />
		</div>
	)
}

function TableSkeleton() {

	return (
		<div className={cl.container}>
			<div className={cl.header}>
				<Skeleton width="15%" height="3rem" animation="wave" />
				<Skeleton width="63%" height="3rem" animation="wave" />
				<Skeleton width="20%" height="3rem" animation="wave" />
			</div>
			<LineSkeletonTable />
			<LineSkeletonTable />
			<LineSkeletonTable />
			<LineSkeletonTable />
			<LineSkeletonTable />
			<LineSkeletonTable />
		</div>
	)
}

export default TableSkeleton