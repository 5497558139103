import axios from "axios"


export class TableUsersService {
	static async getUsers() {
		const phone = await localStorage.getItem('phone');
		const token = await localStorage.getItem('token');
		return axios.get(process.env.REACT_APP_MAIN_API_URL + `/getusers/${phone}`, {
			headers: {
				'Authorization': `Basic ${token}`
			}
		})

	}

	static async deleteUser(userPhone) {
		const phone = await localStorage.getItem('phone');
		const token = await localStorage.getItem('token');
		return axios.post(process.env.REACT_APP_MAIN_API_URL + `/deleteuser`, {
			phone,
			userPhone
		}, {
			headers: {
				'Authorization': `Basic ${token}`
			}
		})
	}
}