import React from 'react'
import {
	Routes,
	Route,
	BrowserRouter,
} from "react-router-dom";
import Header from '../screen/Header';
import Login from '../screen/Login';
import Tabs from '../screen/Tabs';

function RoutesComp() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Login />} />
				<Route path="/dashboard" element={<Header />}>
					<Route index element={<Tabs />} />
				</Route>
			</Routes>
		</BrowserRouter>
	)
}

export default RoutesComp