import React, { useEffect, useState } from 'react';

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
//import "primereact/resources/themes/mdc-light-indigo/theme.css"; 
import './style/theme.css'
import './style/Toast.css'
import './style/Table.css'

import RoutesComp from './routes/RoutesComp';
import { ServiceWorkerUpdateListener } from './ServiceWorkerUpdateListener';
import MyModal from './components/MyModal';

function App() {
	const [updateWaiting, setUpdateWaiting] = useState(false);
	const [registration, setRegistration] = useState(null);
	const [swListener, setSwListener] = useState({});

	useEffect(() => {

		if (process.env.NODE_ENV !== "development") {
			let listener = new ServiceWorkerUpdateListener();
			setSwListener(listener);
			listener.onupdateinstalling = (installingEvent) => {
				//console.log("SW installed", installingEvent);
			};
			listener.onupdatewaiting = (waitingEvent) => {
				//console.log("new update waiting", waitingEvent);
				setUpdateWaiting(true);
			};
			listener.onupdateready = (event) => {
				//console.log("updateready event");
				window.location.reload();
			};
			navigator.serviceWorker.getRegistration().then((reg) => {
				listener.addRegistration(reg);
				setRegistration(reg);
			});

			return () => listener.removeEventListener();
		}
	}, [])
	const [openModal, setOpenModal] = useState(false);

	useEffect(() => {
		if (updateWaiting) {
			setOpenModal(true)
		}
	}, [updateWaiting])



	const handleUpdate = () => {
		setOpenModal(false);
		swListener.skipWaiting(registration.waiting);
	}
	const hideModal = () => {
		setOpenModal(false);
	}

	return (<>
		<RoutesComp />
		<MyModal actionYes={handleUpdate} actionNo={ ()=> setOpenModal(false)} visible={openModal} onHide={hideModal}>
				<span>Update is available</span>
		</MyModal>
	</>
	);
}

export default App;
